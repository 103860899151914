<!--
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
    <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-100">
    <body class="h-full">
    ```
  -->

    <div class="h-full-screen min-w-full">
        <Head>
            <Title>{{ title }}</Title>
            <Meta name="description" :content="title" />
        </Head>
        <Teleport to="body">
            <NotificationsHolder></NotificationsHolder>
        </Teleport>
        <PrimaryHeader></PrimaryHeader>
        <div
            :class="[
                env === 'dev' ? 'mt-32' : 'mt-24',
                'container mx-auto sm:px-6 lg:px-8 pt-8 pb-12',
            ]"
        >
            <slot />
        </div>

        <Footer v-if="isHomePage"></Footer>
    </div>
</template>
<script setup lang="ts">
import * as Sentry from "@sentry/nuxt";
import Footer from "@/components/Footer.vue";
import PrimaryHeader from "@/components/PrimaryHeader.vue";
import { useCourse } from "@/composables/course";
import NotificationsHolder from "@/components/general/NotificationsHolder.vue";
const { $clientPosthog } = useNuxtApp();
const config = useRuntimeConfig();
const nuxtApp = useNuxtApp();
const { courseData, widgetConfig, subdomain } = useCourse();
const route = useRoute();
const isHomePage = route.path === "/";
const env = config.public?.env || "dev";
const title = ref("Golf Course Chatbot");
useHead({
    htmlAttrs: {
        class: "light h-full bg-gray-50",
    },
    link: [
        {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "/apple-touch-icon.png",
        },
        {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: "/favicon-32x32.png",
        },
        {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: "/favicon-16x16.png",
        },
        {
            rel: "manifest",
            href: "/site.webmanifest",
        },
    ],
});
const setHeaders = (courseData: any = {}) => {
    title.value = `${courseData.courseName || "CourseRev"} Chatbot`;
    document.title = title.value;
};

watch(courseData, (newData) => {
    if (newData) {
        setHeaders(courseData.value);
        $clientPosthog?.register({
            courseName: courseData.value?.courseName,
            courseId: courseData.value?.courseId,
        });
        Sentry.setTag("courseName", courseData.value?.courseName);
    }
});
nuxtApp.hook("page:finish", () => {
    if (import.meta.client) {
        window.onNuxtLoading?.(false);
    }
});

const safeJsonParse = (str: string, fallback = {}) => {
    try {
        // First attempt single parse
        try {
            return JSON.parse(str);
        } catch {
            // If single parse fails, try double parse
            return JSON.parse(JSON.parse(str));
        }
    } catch (error) {
        console.warn("Failed to parse JSON config:", error);
        return fallback;
    }
};

const fetchPageConfig = () => {
    const encodedConfig = route.query?.config || "";
    if (encodedConfig) {
        const decodedConfig = atob(encodedConfig as string);
        console.log("decoded iframe config:", decodedConfig);
        const config = safeJsonParse(decodedConfig, {});
        console.log("parsed iframe config:", config);
        widgetConfig.value = config?.courseConfig || {};
    }
};

onMounted(async () => {
    Sentry.setTag("subdomain", subdomain.value);
    Sentry.getCurrentScope().setTransactionName("DefaultLayout");
    fetchPageConfig();
});
</script>
<style lang="css">
body {
    font-family: "Open Sans", Verdana, Geneva, Tahoma, sans-serif;
}
</style>
